import { graphql } from "gatsby"
import React from "react"
import HeroBanner from "../components/common/heroBanner/heroBanner"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Testimonial from "../components/common/testimonial/testimonial"
import Librairie from "../components/generic/librairie/librairie"
import GenericComponents from "../components/generic/genericComponents"
import BlockSliderRubrique from "../components/generic/blockRubriques/blockSliderRubrique"
import AskAQuote from "../components/common/forms/askAQuote"
import { useIntl } from "gatsby-plugin-react-intl"
import { getMetaDescriptionFromComponents, setSeoData } from "../services/seo.service"
import {
  filterTestimonials,
  getCarouselImageOG,
} from "../components/common/functions"
import BlockBooking from "../components/common/blockBooking/blockBooking"

export default function Hotel({
  data: { nodeArticleHotel: hotel },
  pageContext,
}) {
  const {
    field_request_a_quote_case_a_coc,
    field_background_color,
    field_seo,
    field_id: hotelBookingId,
    relationships: {
      field_hero: heroItems,
      field_components: components,
      field_testimonials_container: testimonials,
      field_librairie_d_images: images,
      field_related_contents: relatedContents,
      field_background_image,
      location,
      fieldrubrique,
    },
    title,
  } = hotel

  const intl = useIntl()

  const carouselFiltered = heroItems.filter(
    hero => hero.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )

  seoData.meta.description = getMetaDescriptionFromComponents(field_seo, componentsFiltered);

  const backgroundRelatedContent = {
    field_background_image,
    field_background_color,
  }

  const isSSR = typeof window === "undefined"
  const rubriqueName = fieldrubrique?.name

  let pageUrl;
  if (!isSSR) {
    pageUrl = window.location.href
  }

  const formValues = [
    { name: "type_of_request", value: rubriqueName },
    { name: "business_unit_name", value: rubriqueName + ": " + title },
    { name: "page_url", value: pageUrl },
  ]

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {carouselFiltered && <HeroBanner hero={carouselFiltered} isHotel={1} />}
      <GenericComponents
        components={componentsFiltered}
        askQuoteForm={field_request_a_quote_case_a_coc}
      />
      {filterTestimonials(testimonials).length > 0 && (
        <Testimonial content={testimonials} />
      )}
      {images && <Librairie library={images} />}
      {relatedContents.length > 0 && (
        <BlockSliderRubrique
          content={relatedContents}
          background={backgroundRelatedContent}
          rubrique={fieldrubrique}
        />
      )}
      {field_request_a_quote_case_a_coc && <AskAQuote location={location} formValues={formValues} />}
      {hotelBookingId && <BlockBooking hotelBookingId={hotelBookingId} />}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!) {
    nodeArticleHotel(id: { eq: $id }, langcode: { eq: $language }) {
      ...articleHotelFields
    }
  }
`
