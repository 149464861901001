import React from "react"
import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./blockBooking.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useIntl } from "gatsby-plugin-react-intl"
import fr from "date-fns/locale/fr"
import * as moment from "moment"
import { addDays } from "date-fns"

registerLocale("fr", fr)
const BlockBooking = ({ hotelBookingId }) => {
  const intl = useIntl()

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addDays(new Date(), 2))
  const baseUrl = "https://be.synxis.com/"

  let configcode, themecode, dest, lang

  switch (intl.locale) {
    case "fr":
      lang = "fr-FR"
      break
    default:
      lang = "en-US"
  }

  switch (hotelBookingId) {
    case "59976":
      configcode = "new_BE"
      dest = "HERITAGE"
      themecode = "TELFAIRTHEME"
      break
    case "59973":
      configcode = "HRA"
      dest = "HERITAGE"
      themecode = "HRA"
      break
    case "59995":
      configcode = "HRTV"
      dest = "HERITAGE"
      themecode = "HRTV"
      break
    default:
      break
  }
  return (
    <section className="bookingBar">
      <form action={baseUrl} method="GET" target="_blank">
        <Container>
          <Row>
            <input type="hidden" name="hotel" value={hotelBookingId} />
            <Col md={3}>
              <label>{intl.formatMessage({ id: "bookingForm.arrival" })}</label>

              <DatePicker
                selected={startDate}
                //onChange={date => setStartDate(date); setEndDate(date) }}
                onChange={date => {
                  setStartDate(date)
                  setEndDate(addDays(date, 2))
                }}
                selectsStart
                minDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMMM yy"
                locale={intl.locale}
              />
            </Col>
            <Col md={3}>
              <label>
                {intl.formatMessage({ id: "bookingForm.departure" })}
              </label>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={addDays(startDate, 2)}
                dateFormat="d MMMM yy"
                locale={intl.locale}
              />
            </Col>
            <Col md={3}>
              <label>
                {intl.formatMessage({ id: "bookingForm.traveler" })}
              </label>
              <select name="adult" id="say">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </Col>
            <input
              type="hidden"
              name="arrive"
              value={moment(startDate).format("yyyy-MM-DD")}
            />
            <input
              type="hidden"
              name="depart"
              value={moment(endDate).format("yyyy-MM-DD")}
            />
            <input type="hidden" name="chain" value={14463} />
            {configcode && (
              <input type="hidden" name="configcode" value={configcode} />
            )}
            {dest && <input type="hidden" name="dest" value={dest} />}
            {themecode && (
              <input type="hidden" name="themecode" value={themecode} />
            )}
            <input type="hidden" name="start" id="start" value="availresults" />
            <input type="hidden" name="locale" value={lang} />
            <Col md={3}>
              <button type="submit" className="btn btnPrimary">
                {intl.formatMessage({ id: "bookingForm.check" })}
              </button>
            </Col>
          </Row>
        </Container>
      </form>
    </section>
  )
}

export default BlockBooking
